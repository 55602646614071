<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <!-- blog post section -->
    <section v-else class="post">
      <b-container>
        <b-row>
          <b-col cols="12" md="8">
            <router-link :to="{ name: 'NewsroomPage' }" class="post__link"><img class="post__arrow post__arrow--left" src="@/assets/images/post/arrow-left.svg" alt="arrow" />All articles</router-link>
            <h1 v-html="$prismic.asHtml(this.title)" class="post__title"></h1>
            <p class="post__info">{{ this.date }} • {{ $prismic.richTextAsPlain(this.author) }}</p>
            <div class="post__content" v-html="$prismic.asHtml(this.text)"></div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="post__image" v-lazy:background-image="this.image"></div>
            <div v-if="this.nextPostUid" class="post__next-article">
              <router-link :to="{ name: 'NewsroomPostPage', params: { id: this.nextPostUid } }" class="post__link">Next article<img class="post__arrow post__arrow--right" src="@/assets/images/post/arrow-right.svg" alt="arrow" /></router-link>
              <h2 class="post__title post__title--small" v-line-clamp:32="2">{{ $prismic.richTextAsPlain(this.nextPostTitle) }}</h2>
              <p class="post__info post__info--normal">{{ this.nextPostDate }} • {{ $prismic.richTextAsPlain(this.nextPostAuthor) }}</p>
            </div>
            <div class="post__share">
              <h3 class="post__title post__title--small">Share this article</h3>
              <div class="share-this">
                <div class="sharethis-inline-share-buttons"></div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- aside section -->
    <AsideComponent />
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'NewsroomPostPage',
  components: {
    Spinner,
    AsideComponent: () => import('@/components/AsideComponent/AsideComponent.vue')
  },
  data: () => {
    return {
      cmsContentPending: false,
      title: null,
      date: null,
      author: null,
      image: null,
      text: null,
      nextPostTitle: null,
      nextPostDate: null,
      nextPostAuthor: null,
      nextPostUid: null
    }
  },
  methods: {
    formatDate (date) {
      const monthNames = ['January', 'February', 'March', 'March', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const originDate = new Date(date)
      return originDate.getDate() + ' ' + monthNames[originDate.getMonth()] + ' ' + originDate.getFullYear()
    },
    async getNewsroomPostContent () {
      // query a single post
      const post = await this.$prismic.client.getByUID('news', this.$route.params.id)
      this.title = post.data.title
      this.date = this.formatDate(post.data.date)
      this.author = post.data.author
      this.image = post.data.image.url
      this.text = post.data.text
      // meta section
      window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(post.data.title) + ' - Chimni'
      window.document.querySelector('head meta[name="description"]').setAttribute('content', this.$prismic.richTextAsPlain(post.data.meta_description))
      // query a next post
      const nextPost = (await this.$prismic.client.query(this.$prismic.predicates.at('document.type', 'news'), { pageSize: 1, after: `${post.id}`, orderings: '[my.news.date]' })).results[0]
      if (nextPost !== undefined) {
        this.nextPostTitle = nextPost.data.title
        this.nextPostDate = this.formatDate(nextPost.data.date)
        this.nextPostAuthor = nextPost.data.author
        this.nextPostUid = nextPost.uid
      } else {
        this.nextPostTitle = null
        this.nextPostDate = null
        this.nextPostAuthor = null
        this.nextPostUid = null
      }
      this.cmsContentPending = true

      // sharethis script init
      const st = window.__sharethis__
      if (!st) {
        const script = document.createElement('script')
        const propertyId = process.env.VUE_APP_SHARE_THIS_PROPERTY_ID
        script.src = `https://platform-api.sharethis.com/js/sharethis.js#property=${propertyId}&product=inline-share-buttons`
        document.body.appendChild(script)
      } else if (typeof st.initialize === 'function') {
        st.href = window.location.href
        st.initialize()
      }
    }
  },
  created () {
    this.getNewsroomPostContent()
  },
  watch: {
    '$route.params.id': function (id) {
      this.cmsContentPending = false
      this.getNewsroomPostContent()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./NewsroomPostPage.scss";
</style>
